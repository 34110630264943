import React from 'react';
import { Button, Col, Form, ListGroup, Row } from 'react-bootstrap';

import type {
  InitialElectionCostResponse,
  UpdateQualifyingLifeEventParams,
} from '@liferaft/api/resources';
import {
  createCOBRAElection,
  getInitialElectionCost,
  getPaymentAccount,
  updateQualifyingLifeEvent,
} from '@liferaft/api/resources';
import type {
  COBRAEligibleEnrollment,
  GroupMember,
  MoovPaymentMethod,
  PaymentAccount,
  QualifyingLifeEvent,
  User,
} from '@liferaft/api/types';
import { MoovPaymentMethodType } from '@liferaft/api/types';
import type { NoBody } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import { ReactComponent as NavigationLeftIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Navigation/Arrow-left.svg';
import { Card, Modal, MoovPayments } from '@liferaft/core/components';
import { useDebounce } from '@liferaft/core/hooks';
import { getDisplayDate } from '@liferaft/core/utils/dates';
import { usDollarFormat } from '@liferaft/core/utils/money';

type COBRAModalProps = {
  enrollments: COBRAEligibleEnrollment[];
  groupMember: GroupMember;
  onComplete: () => void;
  onUserChange: (user?: User) => void;
  qualifyingLifeEvent: QualifyingLifeEvent;
  show: boolean;
  user: User;
};

enum COBRAModalStep {
  EXPLANATION = 'explanation',
  ELECTIONS = 'elections',
  PAYMENT = 'payment',
  CONFIRMATION = 'confirmation',
}

export function COBRAModal({
  enrollments,
  groupMember,
  onComplete,
  onUserChange,
  qualifyingLifeEvent,
  show,
  user,
}: COBRAModalProps) {
  const [currentStep, setCurrentStep] = React.useState<COBRAModalStep>(
    COBRAModalStep.EXPLANATION
  );
  const [electedEnrollments, setElectedEnrollments] = React.useState<
    COBRAEligibleEnrollment[]
  >([]);
  const [paymentAccount, setPaymentAccount] = React.useState<PaymentAccount>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState<MoovPaymentMethod>();
  const [initialElectionCosts, setInitialElectionCosts] = React.useState<
    Record<string, string>
  >({});

  React.useEffect(() => {
    const network = new NetworkController();

    if (user.payment_account_id) {
      network.request<NoBody, PaymentAccount>(
        getPaymentAccount(user.payment_account_id, user.id),
        (result) => {
          if (result.error)
            throw new Error('Could not fetch payment account for user.');

          setPaymentAccount(result.data);
        }
      );
    }

    () => void network.cancel();
  }, [user]);

  React.useEffect(() => {
    const network = new NetworkController();

    if (electedEnrollments.length) {
      const newCosts = { ...initialElectionCosts };

      for (const enrollment of electedEnrollments) {
        if (!(enrollment.id in initialElectionCosts)) {
          network.request<NoBody, InitialElectionCostResponse>(
            getInitialElectionCost({
              enrollment_id: enrollment.id,
              enrollment_type: enrollment.enrollment_type,
            }),
            (result) => {
              if (result.error) return;
              if (result.data) {
                newCosts[enrollment.id] = result.data.cost;
              }
            }
          );
        }
      }

      setInitialElectionCosts(newCosts);
    }
  }, [electedEnrollments]);

  const handleSelectEnrollment = (enrollment: COBRAEligibleEnrollment) => {
    if (electedEnrollments.some((e) => e.id === enrollment.id)) {
      setElectedEnrollments((prev) => [
        ...prev.filter((e) => e.id !== enrollment.id),
      ]);
    } else {
      setElectedEnrollments((prev) => [...prev, enrollment]);
    }
  };

  let monthlyElectionTotal = 0;
  for (const e of electedEnrollments) {
    monthlyElectionTotal += Number(e.per_member_admin_fee);
    monthlyElectionTotal += Number(e.per_member_reserve_amount);
  }

  let costToElectTotal = 0;
  Object.values(initialElectionCosts).forEach((cost) => {
    costToElectTotal += Number(cost);
  });

  const handleCompleteElections = async () => {
    const network = new NetworkController();

    let anyErrors = false;
    if (electedEnrollments.length && selectedPaymentMethod) {
      const requestConfigurations = electedEnrollments.map((enrollment) =>
        createCOBRAElection(user.id, groupMember.id, {
          enrollment_id: enrollment.id,
          enrollment_type: enrollment.enrollment_type,
          elected_on: new Date().toISOString(),
          payment_method_id: selectedPaymentMethod.id,
          qualifying_life_event_id: qualifyingLifeEvent.id,
        })
      );

      const results = await network.requestAll(...requestConfigurations);
      anyErrors = results.some((result) => result.error);
    }

    if (!anyErrors) {
      await network.request<
        UpdateQualifyingLifeEventParams,
        QualifyingLifeEvent
      >(
        updateQualifyingLifeEvent(
          user.id,
          groupMember.id,
          qualifyingLifeEvent.id,
          { election_process_complete: true }
        )
      );
    }

    onComplete();
  };

  let content,
    continueDisbaled,
    continueLabel = 'Continue',
    onContinue: () => any = () => undefined,
    previousStep: COBRAModalStep | undefined,
    title;
  switch (currentStep) {
    case COBRAModalStep.EXPLANATION: {
      content = (
        <>
          <p>
            We have received notice of a qualfiying life event,{' '}
            {qualifyingLifeEvent.event_type_display}, which occured on{' '}
            {getDisplayDate(qualifyingLifeEvent.occured_on)}.
          </p>
          <p>
            This event makes you eligible to elect COBRA for one or more of your
            benefits provided by {groupMember.group_name}.
          </p>

          <p>
            Electing a benefit for COBRA allow you to continue the eligible
            benefits provided through the employer that was providing the
            bbenefits until you have another benefits provider, as long as you
            take over the payment for these benefits.
          </p>

          <p className="font-weight-bold">Election takes a couple steps:</p>
          <ol>
            <li>Choose which benefits to elect.</li>
            <li>
              Link a payent method to continue payment for the chosen benefits.
            </li>
            <li>Confirm elections</li>
          </ol>
        </>
      );
      continueDisbaled = false;
      onContinue = () => setCurrentStep(COBRAModalStep.ELECTIONS);
      title = 'COBRA eligibility';
      break;
    }
    case COBRAModalStep.ELECTIONS: {
      content = (
        <div>
          <p>
            Choose which benefits to elect COBRA for. You will be required to
            take over payment for the benefits that are elected.
          </p>

          <p>
            If you do not want to elect any benefits, do not select any of the
            listed benefits and press 'Continue'.
          </p>
          {enrollments
            .filter((e: COBRAEligibleEnrollment) => !e.cobra_election)
            .map((enrollment, i) => (
              <Row key={i} onClick={() => handleSelectEnrollment(enrollment)}>
                <Col>
                  <Card>
                    <Row>
                      <Col>
                        <h3>{enrollment.benefit_type_display}</h3>
                        <p className="text-gray-700">
                          {usDollarFormat.format(
                            Number(enrollment.per_member_admin_fee) +
                              Number(enrollment.per_member_reserve_amount)
                          )}{' '}
                          /per month
                        </p>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <Form.Check
                          checked={electedEnrollments.some(
                            (e: COBRAEligibleEnrollment) =>
                              e.id === enrollment.id
                          )}
                          inline
                          readOnly
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ))}
          <Row className="mt-6 flex-column">
            <Col className="d-flex justify-content-end mr-1">
              <p className="font-weight-bold">
                Monthly Total: {usDollarFormat.format(monthlyElectionTotal)}
              </p>
            </Col>
            <Col className="d-flex flex-column text-right justify-content-end mr-1">
              <p className="font-weight-bold">
                Total due today: {usDollarFormat.format(costToElectTotal)}
              </p>
              <small className="w-50 ml-auto text-gray-600">
                Due today total accounts for months that may have passed since
                coverage through the employer ended.
              </small>
            </Col>
          </Row>
        </div>
      );
      continueDisbaled = false;
      onContinue = () => {
        if (electedEnrollments.length > 0) {
          setCurrentStep(COBRAModalStep.PAYMENT);
        } else {
          setCurrentStep(COBRAModalStep.CONFIRMATION);
          setSelectedPaymentMethod(undefined);
        }
      };
      previousStep = COBRAModalStep.EXPLANATION;
      title = 'Elect Benefits for COBRA';
      break;
    }
    case COBRAModalStep.PAYMENT: {
      content = (
        <MoovPayments
          initiallySelectedPaymentMethodId={selectedPaymentMethod?.id}
          onPaymentMethodSelect={setSelectedPaymentMethod}
          onResourceCreated={setSelectedPaymentMethod}
          onTermsAcceptance={onUserChange}
          paymentAccount={paymentAccount}
        />
      );
      continueDisbaled = !selectedPaymentMethod;
      onContinue = () => setCurrentStep(COBRAModalStep.CONFIRMATION);
      previousStep = COBRAModalStep.ELECTIONS;
      title = 'Setup a payment method to pay for your COBRA elections';
      break;
    }
    case COBRAModalStep.CONFIRMATION: {
      let paymentMethodDescription;

      if (
        selectedPaymentMethod &&
        selectedPaymentMethod.payment_method_type ===
          MoovPaymentMethodType.BANK_ACCOUNT
      ) {
        paymentMethodDescription = `${selectedPaymentMethod.bank_name} ending in ${selectedPaymentMethod.bank_last_four_account_number}`;
      } else if (
        selectedPaymentMethod &&
        selectedPaymentMethod.payment_method_type === MoovPaymentMethodType.CARD
      ) {
        paymentMethodDescription = `Card belonging to ${selectedPaymentMethod.card_holder_name} ending in ${selectedPaymentMethod.card_last_four}`;
      }

      content = electedEnrollments.length ? (
        <div>
          <p>
            You have chosen to elect COBRA for one or more of your benefits.
            Please review your elections.
          </p>
          <ListGroup>
            {electedEnrollments.map((enrollment, i) => (
              <ListGroup.Item key={i}>
                <Row>
                  <Col>
                    <h3>{enrollment.benefit_type_display}</h3>
                    <p className="text-gray-700">
                      {usDollarFormat.format(
                        Number(enrollment.per_member_admin_fee) +
                          Number(enrollment.per_member_reserve_amount)
                      )}{' '}
                      /per month
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Row className="mt-6">
            <Col>
              <p>
                You will be billed a one time total for time between now and
                when your coverage originally ended in order to ensure there
                will be no gap in coverage. The amount,{' '}
                <span className="font-weight-bold">
                  {usDollarFormat.format(costToElectTotal)},
                </span>{' '}
                will be billed to {paymentMethodDescription} to account for the
                gap in coverage for the elections listed above.
              </p>
              <p>
                Additionally, based on the elections listed above,{' '}
                <span className="font-weight-bold">
                  {usDollarFormat.format(monthlyElectionTotal)}
                </span>{' '}
                will be billed to {paymentMethodDescription} each month.
              </p>
            </Col>
          </Row>
        </div>
      ) : (
        <p>
          You have chosen not to elect and benefits under COBRA. If this is
          correct, click 'Submit' to continue to your dashboard.{' '}
        </p>
      );
      continueDisbaled = false;
      continueLabel = 'Confirm';
      onContinue = handleCompleteElections;

      if (electedEnrollments.length || selectedPaymentMethod) {
        previousStep = COBRAModalStep.PAYMENT;
      } else {
        previousStep = COBRAModalStep.ELECTIONS;
      }

      title = 'Reivew and confirm your elections';
      break;
    }
    default: {
      break;
    }
  }

  const [handleContinue, isContinuing] = useDebounce(onContinue);
  const isOnFirstStep = currentStep === COBRAModalStep.EXPLANATION;

  return (
    <Modal
      footer={
        <Row className="w-100 just-content-between">
          {!isOnFirstStep && (
            <Col>
              <Button
                className="icon text-primary float-left p-0"
                onClick={() => previousStep && setCurrentStep(previousStep)}
                role="button"
                variant="empty">
                <NavigationLeftIcon viewBox="0 0 24 24" />
              </Button>
            </Col>
          )}
          <Col>
            <Button
              className="float-right"
              disabled={continueDisbaled || isContinuing}
              onClick={handleContinue}
              variant="primary">
              {continueLabel}
            </Button>
          </Col>
        </Row>
      }
      modalTitle={title}
      show={show}>
      {content}
    </Modal>
  );
}
