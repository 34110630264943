import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';

import { FormError } from '.';
import { DJANGO_API_URL } from '../../environment';
import { useDebounce, useMoov } from '../../hooks';

type Props = {
  acceptLabel?: string;
  autoConfirm?: boolean;
  content?: React.ReactNode;
  declineLabel?: string;
  onAccept: (token: string, onError: () => void) => Promise<void>;
  onDecline?: () => void;
};

export const MOOV_TERMS_FORM_ID = 'moov-terms-form';

export function MoovTermsForm({
  acceptLabel = 'Confirm',
  content,
  declineLabel,
  onAccept,
  onDecline,
  autoConfirm = true,
}: Props) {
  const [error, setError] = React.useState<boolean>(false);
  const [confirmed, setConfirmed] = React.useState<boolean>(false);
  const [hasAgreedTerms, setHasAgreedTerms] = React.useState<boolean>(false);
  const { moov } = useMoov();

  const [handleSubmit, isSubmitting] = useDebounce((token: string) => {
    return onAccept(token, () => {
      setHasAgreedTerms(false);
      setConfirmed(false);
      setError(true);
    });
  });

  React.useEffect(() => {
    (async () => {
      if (confirmed && moov) {
        try {
          const { token } = await moov.accounts.getTermsOfServiceToken();
          handleSubmit(token);
        } catch {
          setConfirmed(false);
          setError(true);
        }
      }
    })();
  }, [confirmed, moov]);

  const handleChange = () => {
    const selection = !hasAgreedTerms;

    setHasAgreedTerms(selection);

    if (autoConfirm && selection) {
      setConfirmed(true);
    }
  };

  return (
    <>
      {autoConfirm && isSubmitting ? (
        <div className="d-flex justify-content-center">
          <Spinner
            animation="border"
            className="spinner-custom"
            variant="primary"
          />
        </div>
      ) : (
        <Form id={MOOV_TERMS_FORM_ID}>
          {error && (
            <FormError>
              <p className="mb-0 text-center">
                There was an issue processing your agreement.
              </p>
            </FormError>
          )}
          {content}
          <Form.Group className="mb-6">
            <Form.Check
              checked={hasAgreedTerms}
              disabled={autoConfirm && confirmed}
              id="has-agreed-terms"
              label={
                <>
                  I have read and agree to Liferaft's{' '}
                  <a
                    href={`${DJANGO_API_URL}/company/tos/`}
                    rel="noreferrer"
                    target="_blank">
                    Terms and Conditions
                  </a>{' '}
                  and{' '}
                  <a
                    href={`${DJANGO_API_URL}/company/privacy/`}
                    rel="noreferrer"
                    target="_blank">
                    Privacy Policy
                  </a>
                  .
                </>
              }
              name="has-agreed-terms"
              onChange={handleChange}
              required
              type="checkbox"
            />
          </Form.Group>
        </Form>
      )}
      {!autoConfirm && (
        <div className="d-flex justify-content-end">
          <Button
            className="mr-2"
            disabled={!hasAgreedTerms || confirmed || isSubmitting}
            onClick={() => {
              setConfirmed(true);
              setError(false);
            }}
            variant="empty">
            {confirmed ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <>{acceptLabel}</>
            )}
          </Button>
          {declineLabel && (
            <Button
              disabled={confirmed || isSubmitting}
              onClick={onDecline}
              variant="outline-primary">
              {declineLabel}
            </Button>
          )}
        </div>
      )}
    </>
  );
}
