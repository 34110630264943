import React from 'react';
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';

import {
  getPaymentMethod,
  getQualifyingLifeEvents,
  getUserGroupMembers,
  updateCOBRAElection,
} from '@liferaft/api/resources';
import type {
  COBRAElection,
  COBRAEligibleEnrollment,
  GroupEnrollment,
  GroupMember,
  MoovPaymentMethod,
  QualifyingLifeEvent,
} from '@liferaft/api/types';
import { GroupBenefitClaimStatusDisplayLabel } from '@liferaft/api/types';
import type { DjangoListResponse } from '@liferaft/api/utils/django-utils';
import type { NetworkResult, NoBody } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import {
  Card,
  MoovPaymentsModal,
  ReimbursementAccountCard,
} from '@liferaft/core/components';
import { useUserContext } from '@liferaft/core/contexts';
import { getDisplayDate } from '@liferaft/core/utils/dates';
import * as GroupEnrollmentUtils from '@liferaft/core/utils/group-enrollment';
import { usDollarFormat } from '@liferaft/core/utils/money';
import * as PaymentMethodUtils from '@liferaft/core/utils/payment-method';

import { COBRAModal } from '@/components';
import { SUPPORT_EMAIL, TOLL_FREE_NUMBER } from '@/environment';
import { ClaimFormModal } from '@/pages';

export function Dashboard() {
  const { user, refresh: refreshUser } = useUserContext();

  const [qualifyingLifeEvents, setQualifyingLifeEvents] =
    React.useState<QualifyingLifeEvent[]>();
  const [groupMember, setGroupMember] = React.useState<GroupMember>();
  const [enrollments, setEnrollments] = React.useState<GroupEnrollment[]>();
  const [showClaimFormModal, setShowClaimFormModal] =
    React.useState<boolean>(false);

  const fetchQualifyingEvents = (
    groupMemberId: string,
    network?: NetworkController
  ) => {
    network = network || new NetworkController();
    network.request<NoBody, DjangoListResponse<QualifyingLifeEvent>>(
      getQualifyingLifeEvents(user!.id, groupMemberId),
      (result: NetworkResult<DjangoListResponse<QualifyingLifeEvent>>) => {
        if (result.error) return;
        if (result.data?.results) setQualifyingLifeEvents(result.data.results);
      }
    );
  };

  React.useEffect(() => {
    const network = new NetworkController();

    if (user && groupMember) {
      fetchQualifyingEvents(groupMember.id, network);
    }

    return () => void network.cancel();
  }, [user, groupMember]);

  const fetchGroupMember = (userId: string, network?: NetworkController) => {
    network = network || new NetworkController();

    network.request<NoBody, DjangoListResponse<GroupMember>>(
      getUserGroupMembers(userId),
      (result: NetworkResult<DjangoListResponse<GroupMember>>) => {
        if (result.error) return;
        if (result.data?.results?.length)
          setGroupMember(result.data.results[0]);
      }
    );
  };

  React.useEffect(() => {
    if (user) {
      const network = new NetworkController();
      fetchGroupMember(user.id, network);
      return () => void network.cancel();
    }
  }, [user]);

  React.useEffect(() => {
    if (groupMember) {
      const benefitMap = groupMember.enrollments.reduce(
        (
          benefitMap: Record<string, GroupEnrollment>,
          enrollment: GroupEnrollment
        ) => {
          const lastFoundEnrollment = benefitMap[enrollment.benefit_id];

          if (lastFoundEnrollment) {
            if (
              'start_date' in enrollment &&
              'start_date' in lastFoundEnrollment &&
              new Date(enrollment.start_date) >
                new Date(lastFoundEnrollment.start_date)
            ) {
              benefitMap[enrollment.benefit_id] = enrollment;
            }
          } else {
            benefitMap[enrollment.benefit_id] = enrollment;
          }

          return benefitMap;
        },
        {} as Record<string, GroupEnrollment>
      );

      setEnrollments(Object.values(benefitMap));
    }
  }, [groupMember]);

  const latestEventRequiringCOBRAElections = qualifyingLifeEvents
    ?.filter((event: QualifyingLifeEvent) => {
      if (event.election_deadline) {
        return (
          new Date() < new Date(event.election_deadline) &&
          !event.election_process_complete
        );
      }

      return !event.election_process_complete;
    })
    .sort(
      (e1, e2) =>
        new Date(e1.occured_on).valueOf() - new Date(e2.occured_on).valueOf()
    )[0];

  if (
    !user ||
    !groupMember ||
    enrollments === undefined ||
    !qualifyingLifeEvents
  ) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner
          animation="border"
          className="spinner-custom"
          variant="primary"
        />
      </div>
    );
  }

  const cobraEligibleEnrollments = enrollments.filter(
    (enrollment: GroupEnrollment): enrollment is COBRAEligibleEnrollment => {
      return 'cobra_election' in enrollment && !enrollment.cobra_election;
    }
  );

  if (latestEventRequiringCOBRAElections && cobraEligibleEnrollments.length) {
    return (
      <COBRAModal
        enrollments={cobraEligibleEnrollments}
        groupMember={groupMember}
        onComplete={() => fetchGroupMember(user.id)}
        onUserChange={() => refreshUser()}
        qualifyingLifeEvent={latestEventRequiringCOBRAElections}
        show={true}
        user={user}
      />
    );
  }

  const cobraElectedEnrollments = enrollments.filter(
    (enrollment: GroupEnrollment): enrollment is COBRAEligibleEnrollment =>
      'cobra_election' in enrollment && !!enrollment.cobra_election
  );

  const claims = enrollments
    .map((e) => {
      if ('claims' in e) return e.claims;
      return [];
    })
    .flat();

  return (
    <Container className="h-100">
      {user.payment_account_id && groupMember.reimbursement_account_id ? (
        <>
          <h3 className="d-inline-block">COBRA Elected Benefits</h3>
          <div className="accent-underline mb-4" />
          {cobraElectedEnrollments.map((enrollment, i) =>
            enrollment.cobra_election ? (
              <COBRAElectionCard
                election={enrollment.cobra_election}
                enrollment={enrollment}
                groupMember={groupMember}
                key={i}
                onUpdatePaymentMethod={() => fetchGroupMember(user.id)}
              />
            ) : null
          )}
          <h3>Claims</h3>
          <div className="accent-underline mb-4" />
          <Card cardClasses="mw-80 mx-auto">
            <Row className="mb-5 d-flex justify-content-end">
              {enrollments && (
                <Col className="d-flex justify-content-end" xs={3}>
                  <Button
                    className="px-2 py-1"
                    onClick={() => setShowClaimFormModal(!showClaimFormModal)}>
                    Submit a Claim
                  </Button>
                  <ClaimFormModal
                    closeForm={() => setShowClaimFormModal(!showClaimFormModal)}
                    enrollments={enrollments}
                    onClaimsChange={() => fetchGroupMember(user.id)}
                    show={showClaimFormModal}
                  />
                </Col>
              )}
            </Row>
            <Row className="px-5">
              {enrollments && claims.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="align-middle">Date Filed</th>
                      <th className="align-middle">Description</th>
                      <th className="align-middle">Requested Amount</th>
                      <th className="align-middle">Reimbursed Amount</th>
                      <th className="align-middle">Status</th>
                      <th className="align-middle">Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {claims.map((claim, i) => (
                      <tr key={i}>
                        <td className="align-middle">
                          {getDisplayDate(claim.created_at)}
                        </td>
                        <td className="align-middle">{claim.description}</td>
                        <td className="align-middle">
                          {usDollarFormat.format(
                            Number(claim.requested_amount_usd)
                          )}
                        </td>
                        <td className="align-middle">
                          {Number(claim.allowed_amount_usd) !== 0
                            ? usDollarFormat.format(
                                Number(claim.allowed_amount_usd)
                              )
                            : '-'}
                        </td>
                        <td className="align-middle">
                          {GroupBenefitClaimStatusDisplayLabel[claim.status]}
                        </td>
                        <td className="align-middle">
                          {claim.admin_notes && claim.admin_notes !== ''
                            ? claim.admin_notes
                            : '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="my-10 w-100 text-center">
                  You have not submitted any claims.
                </div>
              )}
            </Row>
          </Card>
          <h3>Reimbursement Accounts</h3>
          <div className="accent-underline mb-4" />
          <Row className="mb-5 d-flex justify-content-start">
            <Col className="mw-auto mx-auto" md={12} xs={12}>
              <ReimbursementAccountCard
                groupMember={groupMember}
                onGroupMemberChange={() => fetchGroupMember(user.id)}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-center mt-4">
            <p className="mb-0 font-size-sm text-gray-700">
              Need help? Please contact us by{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>email</a> or by phone at{' '}
              {TOLL_FREE_NUMBER}.
            </p>
          </div>
        </>
      ) : (
        <Row>
          <Col className="mx-auto" md={9} xs={12}>
            <ReimbursementAccountCard
              groupMember={groupMember}
              onGroupMemberChange={() => fetchGroupMember(user.id)}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}

type COBRAElectionCardProps = {
  election: COBRAElection;
  enrollment: COBRAEligibleEnrollment;
  groupMember: GroupMember;
  onUpdatePaymentMethod?: () => void;
};

function COBRAElectionCard({
  election,
  enrollment,
  groupMember,
  onUpdatePaymentMethod,
}: COBRAElectionCardProps) {
  const { user } = useUserContext();
  const [paymentMethod, setPaymentMethod] = React.useState<MoovPaymentMethod>();
  const [
    showEditCOBRAElectionPaymentMethod,
    setShowEditCOBRAElectionPaymentMethod,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    const network = new NetworkController();

    if (user.payment_account_id && election.payment_method_id) {
      network.request<NoBody, MoovPaymentMethod>(
        getPaymentMethod(
          user.payment_account_id,
          election.payment_method_id,
          user.id
        ),
        (result: NetworkResult<MoovPaymentMethod>) => {
          if (result.error) return;
          if (result.data) setPaymentMethod(result.data);
        }
      );
    }

    return () => void network.cancel();
  }, [election, user]);

  const [status, statusStyle] =
    new Date(election.start_date) <= new Date()
      ? ['Active', 'text-success']
      : ['Pending', 'text-muted'];

  const cost = GroupEnrollmentUtils.getEnrollmentMonthlyCost(enrollment);

  const handlePaymentMethodSelect = async (
    paymentMethod?: MoovPaymentMethod
  ) => {
    if (paymentMethod) {
      const network = new NetworkController();
      await network.request(
        updateCOBRAElection(user.id, groupMember.id, election.id, {
          payment_method_id: paymentMethod.id,
        })
      );
      setShowEditCOBRAElectionPaymentMethod(false);
      onUpdatePaymentMethod?.();
    }
  };

  let paymentMethodCalloutText = 'Edit payment method';
  if (
    paymentMethod &&
    PaymentMethodUtils.bankAccountRequiresVerification(paymentMethod)
  ) {
    paymentMethodCalloutText = 'Payment method requires verification';
  }

  return (
    <>
      <Card cardClasses="mb-5">
        <Row>
          <Col>
            <div>
              <p className="d-inline-block font-weight-bold mb-1 mr-2">
                {enrollment.benefit_type_display}
              </p>
              <Button
                className="btn-link m-0 p-0 d-inline-block"
                onClick={() => setShowEditCOBRAElectionPaymentMethod(true)}
                variant="empty">
                <span>
                  <small>{paymentMethodCalloutText}</small>
                </span>
              </Button>
            </div>
            <small className="text-gray-600">
              {getDisplayDate(election.start_date)} -{' '}
              {getDisplayDate(election.end_date)}
            </small>
          </Col>
          {cost && (
            <Col className="d-flex flex-column align-items-end justify-content-center">
              <p className={`${statusStyle} mb-1`}>{status}</p>
              <p className="mb-1">{usDollarFormat.format(cost)}/month</p>
              <small className="text-gray-600">
                Billed at the beginning of each month
              </small>
            </Col>
          )}
        </Row>
      </Card>
      <MoovPaymentsModal
        initiallySelectedPaymentMethodId={election.payment_method_id}
        onClose={() => setShowEditCOBRAElectionPaymentMethod(false)}
        onPaymentMethodSelect={handlePaymentMethodSelect}
        onPaymentMethodVerify={onUpdatePaymentMethod}
        show={showEditCOBRAElectionPaymentMethod}
        title="COBRA Election Payment Method"
      />
    </>
  );
}
