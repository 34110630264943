import {
  MoovBankAccountVerificationStatus,
  MoovPaymentMethodType,
} from '@liferaft/api/types';
import type { MoovPaymentMethod } from '@liferaft/api/types';

export function bankAccountRequiresVerification(
  paymentMethod: MoovPaymentMethod
): boolean {
  if (
    paymentMethod.payment_method_type !== MoovPaymentMethodType.BANK_ACCOUNT ||
    paymentMethod.verification_locked
  ) {
    return false;
  }

  if (
    [
      MoovBankAccountVerificationStatus.NEW,
      MoovBankAccountVerificationStatus.PENDING,
      MoovBankAccountVerificationStatus.VERIFICATION_FAILED,
    ].includes(paymentMethod.bank_account_verification_status)
  ) {
    return true;
  }

  return false;
}
