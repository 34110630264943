import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { getPaymentMethod, updateGroupMember } from '@liferaft/api/resources';
import { MoovPaymentMethodType } from '@liferaft/api/types';
import type {
  GroupMember,
  MoovBankAccount,
  MoovPaymentMethod,
} from '@liferaft/api/types';
import type { NetworkResult, NoBody } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card, MoovPaymentsModal } from '.';
import { useUserContext } from '../contexts';
import * as PaymentMethodUtils from '../utils/payment-method';
import './reimbursement-account-card.scss';

type Props = {
  groupMember: GroupMember;
  icon?: React.ReactNode;
  onGroupMemberChange: (groupMember?: GroupMember) => void;
};

export function ReimbursementAccountCard({
  groupMember,
  icon,
  onGroupMemberChange,
}: Props) {
  const { user } = useUserContext();
  const [reimbursementAccount, setReimbursementAccount] = React.useState<
    MoovBankAccount | null | undefined
  >();
  const [showMoovModal, setShowMoovModal] = React.useState(false);

  React.useEffect(() => {
    if (user.payment_account_id && groupMember.reimbursement_account_id) {
      const network = new NetworkController();
      network.request<NoBody, MoovBankAccount>(
        getPaymentMethod(
          user.payment_account_id,
          groupMember.reimbursement_account_id,
          user.id
        ),
        (result: NetworkResult<MoovBankAccount>) => {
          if (result.error) return;

          if (
            result.data &&
            result.data.payment_method_type ===
              MoovPaymentMethodType.BANK_ACCOUNT
          ) {
            setReimbursementAccount(result.data);
          }
        }
      );
    }
  }, [user.payment_account_id, groupMember]);

  const updateGroupMemberReimbursementAccount = async (
    paymentMethod?: MoovPaymentMethod
  ) => {
    if (paymentMethod && paymentMethod.id) {
      const network = new NetworkController();
      const reimbursementAccountResult = await network.request<
        Partial<GroupMember>,
        GroupMember
      >(
        updateGroupMember(groupMember.group_id, groupMember.id, {
          reimbursement_account_id: paymentMethod.id,
        })
      );

      if (reimbursementAccountResult.error) return;
      setShowMoovModal(false);
      onGroupMemberChange(reimbursementAccountResult.data);
    }
  };

  return (
    <>
      <Card cardClasses="mb-6">
        <Row className="mb-5 justify-content-between">
          <Col className="col-md-4" lg={6} md={8} xs={12}>
            <h3 className="mb-0 font-weight-bold">
              {icon}Reimbursement Account
            </h3>
          </Col>
          {reimbursementAccount && (
            <>
              <Col className="d-flex justify-content-end flex-grow-0 card-detail">
                <div className="py-2 mr-4">
                  <p className="small font-weight-bold mb-1">Bank Name</p>
                  <p className="small font-weight-light mb-0">
                    {reimbursementAccount.bank_name}
                  </p>
                </div>
              </Col>
              <Col className="d-flex justify-content-end flex-grow-0 card-detail">
                <div className="py-2 mr-4">
                  <p className="small font-weight-bold mb-1">Account Number</p>
                  <p className="small font-weight-light mb-0">
                    xx{reimbursementAccount.bank_last_four_account_number}
                  </p>
                </div>
              </Col>
            </>
          )}
        </Row>
        <Row className="mb-5 justify-content-between">
          {groupMember && (
            <Col className="d-flex justify-content-end flex-grow-0 card-detail">
              <Button
                className="mb-1 mb-lg-0 mr-lg-1 btn-xs"
                onClick={() => {
                  setShowMoovModal(true);
                }}
                variant="outline-primary">
                Link New Reimbursement Account
              </Button>
            </Col>
          )}
          {reimbursementAccount &&
            PaymentMethodUtils.bankAccountRequiresVerification(
              reimbursementAccount
            ) && (
              <Col className="d-flex justify-content-end flex-grow-0 card-detail">
                <Button
                  className="mb-1 mb-lg-0 mr-lg-1 btn-xs"
                  onClick={() => {
                    setShowMoovModal(true);
                  }}
                  variant="outline-primary">
                  Finish Linking Reimbursement Account
                </Button>
              </Col>
            )}
        </Row>
      </Card>
      <MoovPaymentsModal
        initiallySelectedPaymentMethodId={reimbursementAccount?.id}
        onClose={() => setShowMoovModal(false)}
        onPaymentMethodSelect={updateGroupMemberReimbursementAccount}
        onPaymentMethodVerify={onGroupMemberChange}
        paymentMethodTypes={['bankAccount']}
        show={showMoovModal}
        title="Link Reimbursement Account"
      />
    </>
  );
}
