import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import {
  checkVerificationCode,
  sendVerificationCode,
} from '@liferaft/api/resources';
import { NetworkController } from '@liferaft/api/utils/network';

import { Banner } from '@liferaft/core/components';
import { Input, PhoneNumberInput } from '@liferaft/core/components/forms';
import { ColumnarLayout } from '@liferaft/core/components/layouts';
import { useAuthenticationContext } from '@liferaft/core/contexts';

import VitableLogo from '@/assets/images/logos/Vitable Wordmark - Primary.png';

export function PhoneNumberLogin() {
  const auth = useAuthenticationContext();
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [verificationCode, setVerificationCode] = React.useState<string>('');
  const [hasCodeBeenSent, setHasCodeBeenSent] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const handleSubmit = async () => {
    if (phoneNumber !== '' && verificationCode === '') {
      const network = new NetworkController();

      const sendCodeResult = await network.request(
        sendVerificationCode(phoneNumber)
      );

      if (sendCodeResult.error) {
        setErrorMessage('Error sending verification code.');
      }

      setHasCodeBeenSent(true);
    } else if (phoneNumber !== '' && verificationCode !== '') {
      const network = new NetworkController();

      const checkCodeResult = await network.request(
        checkVerificationCode(phoneNumber, verificationCode)
      );

      if (checkCodeResult.error) {
        setErrorMessage('Incorrect verification code');
      }

      auth.actions.setAuthenticated();
    }
  };

  return (
    <ColumnarLayout>
      <Container className="d-flex flex-column min-vh-100" fluid="md">
        <Row className="mt-2 mb-15 align-items-center justify-content-center no-gutters">
          <Col className="d-flex justify-content-center col-12 col-md-5 col-lg-4">
            <Image src={VitableLogo} width="283.33" />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center no-gutters">
          <Col className="col-12 col-md-5 col-lg-4">
            {errorMessage !== '' && <Banner title={errorMessage} />}
            <h1 className="mb-2 font-weight-bold text-center">
              Login to access your claims
            </h1>

            <PhoneNumberInput
              disabled={hasCodeBeenSent}
              label="Phone Number"
              onChange={(value) => setPhoneNumber(value)}
              required
              value={phoneNumber}
            />
            {hasCodeBeenSent && (
              <Input
                id="verification_code"
                label="Verification Code"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setVerificationCode(e.target.value)
                }
                pattern="^[0-9]{6}$"
                required
                type="text"
                value={verificationCode}
              />
            )}
            <div className="d-flex justify-content-center align-items-center mt-5">
              <Button className="w-100" onClick={handleSubmit}>
                {hasCodeBeenSent ? 'Log In' : 'Send Verification Code'}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </ColumnarLayout>
  );
}
