import React from 'react';
import { Form } from 'react-bootstrap';

import { usDollarFormat } from '../../utils/money';

type Props = {
  error?: string;
  id?: string;
  initialValue?: number;
  label?: string;
  name?: string;
  onChange?: (value?: number) => void;
  placeholder?: string;
};

export function CurrencyInput({
  error,
  id,
  initialValue,
  label,
  name,
  onChange,
  placeholder,
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [formatted, setFormatted] = React.useState<string>(
    initialValue ? usDollarFormat.format(initialValue) : ''
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const cleanedInput = input.replace(/\D/g, '');

    if (cleanedInput === '') {
      return;
    }

    const dollars = cleanedInput.slice(0, -2);
    const cents = cleanedInput.slice(-2);

    const numberValue = parseFloat(dollars + '.' + cents.padStart(2, '0'));
    const formattedValue = usDollarFormat.format(numberValue);

    setFormatted(formattedValue);
    onChange?.(numberValue);
  };

  return (
    <Form.Group className="form-group">
      <Form.Label className="font-weight-bold">{label}</Form.Label>
      <Form.Control
        id={id}
        inputMode="decimal"
        isInvalid={!!error}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        ref={inputRef}
        type="text"
        value={formatted}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}
