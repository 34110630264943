import type { Group, PaymentMethod } from '../types';
import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { NoBody, QueryParams } from '../utils/network';
import type { RequestConfiguration } from '../utils/network';
import type { CreatePaymentMethodParams } from './payment-method';

export function createGroupCensus(
  groupId: string,
  body: FormData
): DjangoRequestConfiguration<FormData> {
  return new DjangoRequestConfiguration<FormData>({
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .post()
    .body(body)
    .url(`/api/groups/${groupId}/censuses/`);
}

export type CreateGroupPaymentAccountParams = {
  terms_of_service_token: string;
};

export function createGroupPaymentAccount(
  groupId: string,
  termsOfServiceToken: string
): DjangoRequestConfiguration<CreateGroupPaymentAccountParams> {
  return new DjangoRequestConfiguration<CreateGroupPaymentAccountParams>()
    .post()
    .body({ terms_of_service_token: termsOfServiceToken })
    .url(`/api/groups/${groupId}/payment-account/`);
}

export function createGroupPaymentAccountPaymentMethod(
  groupId: string,
  paymentAccountId: string,
  body: CreatePaymentMethodParams
): DjangoRequestConfiguration<CreatePaymentMethodParams> {
  return new DjangoRequestConfiguration<CreatePaymentMethodParams>()
    .post()
    .body(body)
    .url(
      `/api/groups/${groupId}/payment-accounts/${paymentAccountId}/payment-methods/`
    );
}

export function createGroupPaymentMethod(
  groupId: string,
  paymentMethodParams: Partial<PaymentMethod>
): DjangoRequestConfiguration<Partial<PaymentMethod>> {
  return new DjangoRequestConfiguration<Partial<PaymentMethod>>()
    .post()
    .body(paymentMethodParams)
    .url(`/api/groups/${groupId}/payment-method/`);
}

export function getGroupPaymentAccount(
  groupId: string,
  paymentAccountId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/groups/${groupId}/payment-accounts/${paymentAccountId}/`);
}

export function getGroupPaymentAccountPaymentMethods(
  groupId: string,
  paymentAccountId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(
      `/api/groups/${groupId}/payment-accounts/${paymentAccountId}/payment-methods/`
    );
}

export function getGroupPaymentAccountPaymentMethod(
  groupId: string,
  paymentAccountId: string,
  paymentMethodId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(
      `/api/groups/${groupId}/payment-accounts/${paymentAccountId}/payment-methods/${paymentMethodId}/`
    );
}

type MoovMicroDepositVerificationParams = {
  amount_1: number;
  amount_2: number;
};

export function verifyGroupPaymentMethod(
  paymentAccountId: string,
  paymentMethodId: string,
  userId: string,
  body: MoovMicroDepositVerificationParams
): DjangoRequestConfiguration<MoovMicroDepositVerificationParams> {
  return new DjangoRequestConfiguration<MoovMicroDepositVerificationParams>()
    .post()
    .body(body)
    .url(
      `/api/groups/${userId}/payment-accounts/${paymentAccountId}/payment-methods/${paymentMethodId}/verify/`
    );
}

export function getGroup(groupId: string): RequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/groups/${groupId}/`);
}

export function getGroupCensuses(
  groupId: string,
  query?: QueryParams
): DjangoRequestConfiguration<NoBody> {
  const configuration = new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/groups/${groupId}/censuses/`);

  if (query) {
    configuration.query(query);
  }

  return configuration;
}

export function getGroupInvoices(
  groupId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/groups/${groupId}/invoices/`);
}

export function getGroups(): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>().get().url('/api/groups/');
}

export function updateGroup(
  groupId: string,
  group: Group
): DjangoRequestConfiguration<Group> {
  return new DjangoRequestConfiguration<Group>()
    .put()
    .body(group)
    .url(`/api/groups/${groupId}/`);
}
