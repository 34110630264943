import React from 'react';
import { Container, Dropdown, Image, Navbar } from 'react-bootstrap';

import { UserRole } from '@liferaft/api/types';

import { ReactComponent as SignoutIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Navigation/Sign-out.svg';
import type { RoleOption } from '@liferaft/core/components/headers';
import { useUserContext } from '@liferaft/core/contexts';

import VitableLogo from '@/assets/images/logos/Vitable Wordmark - Primary.png';
import { MEMBER_APP_URL } from '@/environment';

export type Props = {
  baseUrl: string;
};

export function AuthenticatedHeader({ baseUrl }: Props) {
  const { user } = useUserContext();

  const roleOptions: RoleOption[] = [];

  if (user.roles?.includes(UserRole.MEMBER)) {
    roleOptions.push({
      url: MEMBER_APP_URL as string,
      description: 'Member View',
    });
  }

  return (
    <Navbar bg="primary-extra-light" data-testid="auth-header" expand={false}>
      <Container fluid>
        <Navbar.Brand data-testid="navbar-logo">
          <Image height="38.39" src={VitableLogo} width="142.81" />
        </Navbar.Brand>

        {roleOptions && roleOptions.length > 0 && (
          <Dropdown className="ml-auto mr-0 mr-md-3 mt-3">
            <Dropdown.Toggle
              className="dropdown-override"
              data-testid="auth-header-role-toggle"
              variant="btn btn-light btn-sm">
              MEC View
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-right">
              {roleOptions &&
                roleOptions.map((option, i) => (
                  <Dropdown.Item
                    data-testid="role-dropdown-item"
                    href={option.url}
                    key={i}>
                    {option.description}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        )}

        <Dropdown className="ml-auto ml-md-0 mt-3">
          <Dropdown.Toggle
            className="dropdown-override"
            data-testid="auth-header-menu-toggle"
            variant="btn btn-outline-primary btn-sm">
            Menu
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-right"
            data-testid="auth-header-menu-dropdown">
            <Dropdown.Item
              data-testid="menu-dropdown-item"
              href={`${baseUrl}/user/settings/`}>
              Settings
            </Dropdown.Item>
            <Dropdown.Item
              data-testid="menu-dropdown-item"
              href={`${baseUrl}/user/logout/`}>
              Log out
              <span className="icon text-primary icon-xs">
                <SignoutIcon />
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
}
