import type {
  GroupAutoReimbursementRequest,
  GroupBenefitClaim,
} from '../types';
import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { RequestConfiguration } from '../utils/network';
import type { NoBody, QueryParams } from '../utils/network';

export function listGroupBenefitClaims(
  groupId: string,
  query?: QueryParams
): DjangoRequestConfiguration<NoBody> {
  const configuration = new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/groups/${groupId}/claims/`);

  if (query) {
    configuration.query(query);
  }

  return configuration;
}

export function createGroupExpenseClaim(
  enrollmentId: string,
  body: FormData
): DjangoRequestConfiguration<FormData> {
  return new DjangoRequestConfiguration<FormData>({
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .post()
    .body(body)
    .url(`/api/group-enrollments/${enrollmentId}/claims/`);
}

export function getClaims(query?: QueryParams): RequestConfiguration<NoBody> {
  const configuration = new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/claims/`);

  if (query) {
    configuration.query(query);
  }

  return configuration;
}

export function updateGroupBenefitClaim(
  groupBenefitClaimId: string,
  body: Partial<GroupBenefitClaim>
): DjangoRequestConfiguration<Partial<GroupBenefitClaim>> {
  return new DjangoRequestConfiguration<Partial<GroupBenefitClaim>>()
    .patch()
    .body(body)
    .url(`/api/group-claims/${groupBenefitClaimId}/`);
}

export function createLevelFundedClaim(
  enrollmentId: string,
  body: FormData
): DjangoRequestConfiguration<FormData> {
  return new DjangoRequestConfiguration<FormData>({
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .post()
    .body(body)
    .url(`/api/level-funded-enrollments/${enrollmentId}/claims/`);
}

export type CreateClaimsCsvParams = {
  status?: string;
};

export function createClaimsCsv(
  groupId: string,
  status?: string
): DjangoRequestConfiguration<CreateClaimsCsvParams, Blob> {
  return new DjangoRequestConfiguration<CreateClaimsCsvParams, Blob>()
    .post()
    .body({
      status: status,
    })
    .url(`/api/groups/${groupId}/create-claim-csv/`);
}

export function createAutoReimbursementRequest(
  enrollmentId: string,
  body: FormData
): DjangoRequestConfiguration<FormData> {
  return new DjangoRequestConfiguration<FormData>({
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .post()
    .body(body)
    .url(`/api/group-enrollments/${enrollmentId}/claims/auto-reimbursement/`);
}

export function updateAutoReimbursementRequest(
  autoReimbursementRequestId: string,
  body: Partial<GroupAutoReimbursementRequest>
): DjangoRequestConfiguration<Partial<GroupAutoReimbursementRequest>> {
  return new DjangoRequestConfiguration<
    Partial<GroupAutoReimbursementRequest>
  >()
    .patch()
    .body(body)
    .url(`/api/group-claims/auto-reimbursement/${autoReimbursementRequestId}/`);
}
