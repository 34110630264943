import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { getUser } from '@liferaft/api/resources';

import { PlaidOAuthRedirect } from '@liferaft/core/components';
import { CompanyFooter } from '@liferaft/core/components/footers';
import { ColumnarLayout } from '@liferaft/core/components/layouts';
import { UserContextProvider } from '@liferaft/core/contexts';
import { Plaid } from '@liferaft/core/hooks';
import { NotFound } from '@liferaft/core/pages';

import { AuthenticatedHeader } from '@/components/authenticated-header';
import { DJANGO_API_URL } from '@/environment';
import { Dashboard } from '@/pages';

export function ProtectedEntry() {
  const layout = (
    <ColumnarLayout
      footer={<CompanyFooter baseUrl={DJANGO_API_URL as string} />}
      header={<AuthenticatedHeader baseUrl={DJANGO_API_URL as string} />}
    />
  );

  return (
    <UserContextProvider getUser={getUser}>
      <Routes>
        <Route element={layout} path="/">
          <Route element={<Dashboard />} index />
        </Route>
        <Route element={<PlaidOAuthRedirect />} path={Plaid.OAUTH_PATH} />
        <Route element={<NotFound />} path="*" />
      </Routes>
    </UserContextProvider>
  );
}
