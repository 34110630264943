import type { DependentData, GroupMember } from '../types';
import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { NoBody, QueryParams } from '../utils/network';

export function closeGroupMemberAccount(
  groupId: string,
  memberId: string,
  body: Partial<GroupMember>
): DjangoRequestConfiguration<Partial<GroupMember>> {
  return new DjangoRequestConfiguration<Partial<GroupMember>>()
    .post()
    .body(body)
    .url(`/api/groups/${groupId}/members/${memberId}/terminate-coverage/`);
}

export type AddGroupMemberData = {
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  sex?: string;
  zip_code?: string;
  email_address?: string;
  employment_status?: string;
  salary?: string;
  job_title?: string;
  is_admin?: boolean;
  ichra_coverage_level?: string;
  level_funded_coverage_level?: string;
  supplemental_insurance_coverage_level?: string;
  spouse?: DependentData;
  children?: DependentData[];
};

export function createGroupMember(
  groupId: string,
  body: AddGroupMemberData
): DjangoRequestConfiguration<AddGroupMemberData> {
  return new DjangoRequestConfiguration<AddGroupMemberData>()
    .post()
    .body(body)
    .url(`/api/groups/${groupId}/members/`);
}

export function getGroupMembers(
  groupId: string,
  query?: QueryParams
): DjangoRequestConfiguration<NoBody> {
  const configuration = new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/groups/${groupId}/members/`);

  if (query) {
    configuration.query(query);
  }

  return configuration;
}

export function getUserGroupMembers(
  userId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/users/${userId}/group-members/`);
}

export function updateGroupMember(
  groupId: string,
  memberId: string,
  body: Partial<GroupMember>
): DjangoRequestConfiguration<Partial<GroupMember>> {
  return new DjangoRequestConfiguration<Partial<GroupMember>>()
    .patch()
    .body(body)
    .url(`/api/groups/${groupId}/members/${memberId}/`);
}
