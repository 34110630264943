import React from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import { createLevelFundedClaim } from '@liferaft/api/resources';
import type { GroupEnrollment } from '@liferaft/api/types';
import { NetworkController } from '@liferaft/api/utils/network';

import { Modal } from '@liferaft/core/components';
import type { DateOfBirthData } from '@liferaft/core/components/forms';
import { DateOfBirthInput, Input } from '@liferaft/core/components/forms';
import { useDebounce } from '@liferaft/core/hooks';
import * as dates from '@liferaft/core/utils/dates';

import { SUPPORT_EMAIL, TOLL_FREE_NUMBER } from '@/environment';

type Props = {
  show: boolean;
  closeForm: () => void;
  enrollments: GroupEnrollment[];
  onClaimsChange: () => void;
};

type ClaimData = {
  date_of_expense?: DateOfBirthData;
  requested_amount?: string;
  description?: string;
  documents?: FileList | null;
};

export function ClaimFormModal({
  show,
  closeForm,
  enrollments,
  onClaimsChange,
}: Props) {
  const [selectedEnrollment, setSelectedEnrollment] =
    React.useState<GroupEnrollment>(enrollments[0]);
  const [claim, setClaim] = React.useState<ClaimData>({
    date_of_expense: {
      month: undefined,
      day: undefined,
      year: undefined,
    },
    requested_amount: '',
    description: '',
    documents: undefined,
  });
  const [errors, setErrors] = React.useState<Partial<Record<string, any>>>();

  const [handleSubmit, isSubmitting] = useDebounce(async (event) => {
    event.preventDefault();

    const network = new NetworkController();

    const formData = new FormData(event.target as HTMLFormElement);
    formData.append('enrollment_id', selectedEnrollment.id);

    const date_of_expense = dates.toString({
      month: parseFloat(String(formData.get('dateOfBirthMonth'))),
      day: parseFloat(String(formData.get('dateOfBirthDay'))),
      year: parseFloat(String(formData.get('dateOfBirthYear'))),
    });
    formData.delete('dateOfBirthMonth');
    formData.delete('dateOfBirthDay');
    formData.delete('dateOfBirthYear');

    formData.append('date_of_expense', date_of_expense);

    const createResult = await network.request(
      createLevelFundedClaim(selectedEnrollment.id, formData)
    );

    if (createResult.error) {
      setErrors(createResult.data);
      return;
    }

    setClaim({
      date_of_expense: {
        month: undefined,
        day: undefined,
        year: undefined,
      },
      requested_amount: '',
      description: '',
      documents: undefined,
    });
    setErrors(undefined);

    onClaimsChange();
    closeForm();
  });

  return (
    <Modal modalTitle="Submit a Claim" onHide={closeForm} show={show}>
      <Form onSubmit={handleSubmit}>
        <>
          <Form.Group>
            {errors && (
              <Alert>
                {Object.values(errors).map((error, i) => (
                  <li className="list-unstyled" key={i}>
                    {error}
                  </li>
                ))}
              </Alert>
            )}
            <Row>
              <Col className="pr-0 col-4">
                <Form.Group className="form-group">
                  <Form.Label className="h5">Selected Benefit</Form.Label>
                  <Form.Select
                    onChange={(event) => {
                      const foundEnrollment = enrollments.find(
                        (enrollment) => event.target.value === enrollment.id
                      );

                      if (foundEnrollment)
                        setSelectedEnrollment(foundEnrollment);
                    }}>
                    {enrollments.map((enrollment, i) => (
                      <option
                        key={i}
                        selected={enrollment.id === selectedEnrollment.id}
                        value={enrollment.id}>
                        {enrollment.benefit_type_display}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-0 col-4">
                <Input
                  error={errors?.requested_amount_usd}
                  id="requested_amount_usd"
                  label="Requested Amount"
                  labelClasses="h5"
                  min={0}
                  name="requested_amount_usd"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setClaim({ ...claim, requested_amount: e.target.value })
                  }
                  placeholder="$0.00"
                  required
                  step={0.01}
                  type="number"
                  value={claim?.requested_amount}
                />
              </Col>
              <Col>
                <DateOfBirthInput
                  colClasses={{
                    day: 'px-1',
                    month: 'pr-0',
                    year: 'pl-0',
                  }}
                  label="Date of Expense"
                  labelClasses="h5"
                  onChange={(value: DateOfBirthData) =>
                    setClaim({ ...claim, date_of_expense: value })
                  }
                  value={claim?.date_of_expense}
                />
                <Form.Text>{errors?.date_of_expense}</Form.Text>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Input
                  as="textarea"
                  error={errors?.description}
                  id="description"
                  label="Description"
                  labelClasses="h5"
                  name="description"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setClaim({ ...claim, description: e.target.value })
                  }
                  placeholder="Description"
                  required
                  type="text"
                  value={claim?.description}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Input
                  id="document_files"
                  label="Documents"
                  labelClasses="h5"
                  multiple
                  name="document_files"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setClaim({ ...claim, documents: e.target.files })
                  }
                  required
                  type="file"
                />
                {claim?.documents && claim.documents.length > 0 && (
                  <Form.Text>
                    <p className="mb-1">Uploaded Files:</p>

                    <ul className="list-unstyled">
                      {Array.from(claim.documents).map((file, i) => (
                        <li key={i}>{file.name}</li>
                      ))}
                    </ul>
                  </Form.Text>
                )}
              </Col>
            </Row>
          </Form.Group>
          <div className="mt-4 d-flex justify-content-end">
            <Button disabled={isSubmitting} type="submit">
              {isSubmitting && (
                <Spinner
                  animation="border"
                  as="span"
                  className="mt-1 mx-8"
                  data-testid="spinner"
                  role="status"
                  variant="light"
                />
              )}
              {!isSubmitting && 'Submit'}
            </Button>
          </div>
        </>
      </Form>
      <div className="d-flex justify-content-center mt-4">
        <p className="mb-0 font-size-sm text-gray-700">
          Need help? Please contact us by{' '}
          <a href={`mailto:${SUPPORT_EMAIL}`}>email</a> or by phone at{' '}
          {TOLL_FREE_NUMBER}.
        </p>
      </div>
    </Modal>
  );
}
