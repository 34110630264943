import React from 'react';

export type Store = {
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
  setItem: (key: string, value: string) => void;
  state: Record<string, string | null>;
};

export function useLocalStorage(): Store {
  const [state, setState] = React.useState<Record<string, string | null>>({});

  React.useEffect(() => {
    const onStorage = (event: StorageEvent): void => {
      if (event.storageArea === localStorage) {
        setState((prevState) => ({
          ...prevState,
          [event.key as string]: event.newValue,
        }));
      }
    };

    window.addEventListener('storage', onStorage);

    return (): void => window.removeEventListener('storage', onStorage);
  }, []);

  const getItem = React.useCallback(
    (key: string): string | null => {
      const value = localStorage.getItem(key);

      if (value !== state[key]) {
        setState((prevState) => ({ ...prevState, [key]: value }));
      }

      return value;
    },
    [state]
  );

  const setItem = React.useCallback(
    (key: string, value: string): void => {
      localStorage.setItem(key, value);
      setState((prevState) => ({ ...prevState, [key]: value }));
    },
    [state]
  );

  const removeItem = React.useCallback(
    (key: string): void => {
      localStorage.removeItem(key);

      setState((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    },
    [state]
  );

  return { getItem, removeItem, setItem, state };
}
