import React from 'react';

import {
  getGroupPaymentAccount,
  getPaymentAccount,
} from '@liferaft/api/resources';
import type { Group, PaymentAccount } from '@liferaft/api/types';
import type { NoBody } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import { Modal, MoovPayments } from '../components';
import type { Props as MoovPaymentsProps } from '../components';
import { useUserContext } from '../contexts';

type Props = Pick<
  MoovPaymentsProps,
  | 'initiallySelectedPaymentMethodId'
  | 'onPaymentMethodSelect'
  | 'onPaymentMethodVerify'
  | 'onResourceCreated'
  | 'onTermsAcceptance'
  | 'paymentMethodFilters'
  | 'paymentMethodTypes'
  | 'selectablePredicate'
> & {
  group?: Group;
  onClose: () => void;
  show: boolean;
  title: string;
};

export function MoovPaymentsModal({
  group,
  initiallySelectedPaymentMethodId,
  onClose,
  onPaymentMethodSelect,
  onPaymentMethodVerify,
  onResourceCreated,
  onTermsAcceptance,
  paymentMethodFilters,
  paymentMethodTypes,
  selectablePredicate,
  show,
  title,
}: Props) {
  const { user } = useUserContext();
  const [paymentAccount, setPaymentAccount] = React.useState<PaymentAccount>();

  React.useEffect(() => {
    const network = new NetworkController();

    if (!group && user.payment_account_id) {
      network.request<NoBody, PaymentAccount>(
        getPaymentAccount(user.payment_account_id, user.id),
        (result) => {
          if (result.error)
            throw new Error('Could not fetch payment account for user.');

          setPaymentAccount(result.data);
        }
      );
    }

    () => void network.cancel();
  }, [user]);

  React.useEffect(() => {
    const network = new NetworkController();

    if (group && group.payment_account_id) {
      network.request<NoBody, PaymentAccount>(
        getGroupPaymentAccount(group.id, group.payment_account_id),
        (result) => {
          if (result.error)
            throw new Error('Could not fetch payment account for group.');

          setPaymentAccount(result.data);
        }
      );
    }

    () => void network.cancel();
  }, [group]);

  return (
    <Modal modalTitle={title} onHide={onClose} show={show}>
      <MoovPayments
        groupId={group?.id}
        initiallySelectedPaymentMethodId={initiallySelectedPaymentMethodId}
        onPaymentMethodSelect={onPaymentMethodSelect}
        onPaymentMethodVerify={onPaymentMethodVerify}
        onResourceCreated={onResourceCreated}
        onTermsAcceptance={onTermsAcceptance}
        paymentAccount={paymentAccount}
        paymentMethodFilters={paymentMethodFilters}
        paymentMethodTypes={paymentMethodTypes}
        selectablePredicate={selectablePredicate}
      />
    </Modal>
  );
}
