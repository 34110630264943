import type { COBRAElection, QualifyingLifeEvent } from '../types';
import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { NoBody, QueryParams } from '../utils/network';

export function getQualifyingLifeEvents(
  userId: string,
  groupMemberId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(
      `/api/users/${userId}/group-members/${groupMemberId}/qualifying-life-events/`
    );
}

export type UpdateQualifyingLifeEventParams = Pick<
  QualifyingLifeEvent,
  'election_process_complete'
>;

export function updateQualifyingLifeEvent(
  userId: string,
  groupMemberId: string,
  eventId: string,
  body: UpdateQualifyingLifeEventParams
): DjangoRequestConfiguration<UpdateQualifyingLifeEventParams> {
  return new DjangoRequestConfiguration<UpdateQualifyingLifeEventParams>()
    .patch()
    .body(body)
    .url(
      `/api/users/${userId}/group-members/${groupMemberId}/qualifying-life-events/${eventId}/`
    );
}

export function getCOBRAElections(
  userId: string,
  groupMemberId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(
      `/api/users/${userId}/group-members/${groupMemberId}/cobra-elections/`
    );
}

type CreateCOBRAElectionParams = {
  enrollment_id: string;
  enrollment_type: string;
  elected_on: string;
  payment_method_id?: string;
  qualifying_life_event_id: string;
};

export function createCOBRAElection(
  userId: string,
  groupMemberId: string,
  createParams: CreateCOBRAElectionParams
): DjangoRequestConfiguration<CreateCOBRAElectionParams> {
  return new DjangoRequestConfiguration<CreateCOBRAElectionParams>()
    .post()
    .body(createParams)
    .url(
      `/api/users/${userId}/group-members/${groupMemberId}/cobra-elections/`
    );
}

export function getCOBRAElection(
  userId: string,
  groupMemberId: string,
  electionId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(
      `/api/users/${userId}/group-members/${groupMemberId}/cobra-elections/${electionId}/`
    );
}

type UpdateCOBRAElectionParams = Pick<COBRAElection, 'payment_method_id'>;

export function updateCOBRAElection(
  userId: string,
  groupMemberId: string,
  electionId: string,
  updateParams: UpdateCOBRAElectionParams
): DjangoRequestConfiguration<UpdateCOBRAElectionParams> {
  return new DjangoRequestConfiguration<UpdateCOBRAElectionParams>()
    .patch()
    .body(updateParams)
    .url(
      `/api/users/${userId}/group-members/${groupMemberId}/cobra-elections/${electionId}/`
    );
}

export type InitialElectionCostResponse = {
  cost: string;
};

export function getInitialElectionCost(
  query: QueryParams
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .query(query)
    .url('/api/cobra-elections/initial-cost/');
}
