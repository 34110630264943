const DJANGO_API_URL = process.env.DJANGO_API_URL;
const NODE_ENV = process.env.NODE_ENV;
const PLAID_OAUTH_REDIRECT_URI = process.env.PLAID_OAUTH_REDIRECT_URI;
const RELEASE_SHA = process.env.RELEASE_SHA;
const SEGMENT_KEY = process.env.SEGMENT_KEY;
const SENTRY_DSN = process.env.SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;
const SUPPORT_EMAIL = process.env.SUPPORT_EMAIL;
const TOLL_FREE_NUMBER = process.env.TOLL_FREE_NUMBER;
const VGS_ENVIRONMENT = process.env.VGS_ENVIRONMENT;
const VGS_SATELLITE_PORT = process.env.VGS_SATELLITE_PORT;
const VGS_VAULT_ID = process.env.VGS_VAULT_ID;
const MEMBER_APP_URL = process.env.MEMBER_APP_URL;

export {
  DJANGO_API_URL,
  NODE_ENV,
  PLAID_OAUTH_REDIRECT_URI,
  RELEASE_SHA,
  SEGMENT_KEY,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SUPPORT_EMAIL,
  TOLL_FREE_NUMBER,
  VGS_ENVIRONMENT,
  VGS_SATELLITE_PORT,
  VGS_VAULT_ID,
  MEMBER_APP_URL,
};
