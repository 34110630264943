import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { NoBody, QueryParams } from '../utils/network';

export type MappedMoovResourceType = 'card' | 'bank_account';

interface ICreatePaymentMethodParams<T extends MappedMoovResourceType> {
  payment_method_type: T;
  moov_funding_source_id: string;
}

export type CreateBankAccountParams = {
  bank_account_type: string;
  bank_name: string;
  bank_last_four_account_number: string;
  routing_number: string;
} & ICreatePaymentMethodParams<'bank_account'>;

export type CreateCardParams = {
  card_billing_address: Record<string, string>;
  card_bin?: string;
  card_brand?: string;
  card_expiration: string;
  card_holder_name: string;
  card_issuer?: string;
  card_issuer_country?: string;
  card_last_four?: string;
  card_type?: string;
  card_verification?: string;
} & ICreatePaymentMethodParams<'card'>;

export type CreatePaymentMethodParams =
  | CreateBankAccountParams
  | CreateCardParams;

export function createPaymentMethod(
  paymentAccountId: string,
  userId: string,
  body: CreatePaymentMethodParams
): DjangoRequestConfiguration<CreatePaymentMethodParams> {
  return new DjangoRequestConfiguration<CreatePaymentMethodParams>()
    .post()
    .body(body)
    .url(
      `/api/users/${userId}/payment-accounts/${paymentAccountId}/payment-methods/`
    );
}

export function getPaymentMethods(
  paymentAccountId: string,
  userId: string,
  query?: QueryParams
): DjangoRequestConfiguration<NoBody> {
  const configuration = new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(
      `/api/users/${userId}/payment-accounts/${paymentAccountId}/payment-methods/`
    );

  if (query) {
    configuration.query(query);
  }

  return configuration;
}

export function getPaymentMethod(
  paymentAccountId: string,
  paymentMethodId: string,
  userId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(
      `/api/users/${userId}/payment-accounts/${paymentAccountId}/payment-methods/${paymentMethodId}/`
    );
}

type MoovMicroDepositVerificationParams = {
  amount_1: number;
  amount_2: number;
};

export function verifyPaymentMethod(
  paymentAccountId: string,
  paymentMethodId: string,
  userId: string,
  body: MoovMicroDepositVerificationParams
): DjangoRequestConfiguration<MoovMicroDepositVerificationParams> {
  return new DjangoRequestConfiguration<MoovMicroDepositVerificationParams>()
    .post()
    .body(body)
    .url(
      `/api/users/${userId}/payment-accounts/${paymentAccountId}/payment-methods/${paymentMethodId}/verify/`
    );
}
