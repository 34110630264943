import React from 'react';

type Props = {
  children?: React.ReactNode;
  onClose?: () => void;
};

export function FormError({ children, onClose }: Props) {
  return (
    <div className="alert alert-danger alert-dismissible">
      {onClose && (
        <button
          aria-label="close"
          className="close"
          onClick={onClose}
          type="button">
          ×
        </button>
      )}
      {children}
    </div>
  );
}
