import type {
  BankAccount,
  Drops,
  Card as MoovCard,
  OnResourceCreated,
  ResourceType,
} from '@moovio/moov-js';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import type {
  CreatePaymentAccountParams,
  CreatePaymentMethodParams,
} from '@liferaft/api/resources';
import { createGroupPaymentAccount } from '@liferaft/api/resources';
import {
  createGroupPaymentAccountPaymentMethod,
  verifyGroupPaymentMethod,
} from '@liferaft/api/resources';
import { getGroupPaymentAccountPaymentMethods } from '@liferaft/api/resources';
import {
  createPaymentAccount,
  createPaymentMethod,
  getPaymentMethods,
  verifyPaymentMethod,
} from '@liferaft/api/resources';
import type { MoovPaymentMethod, PaymentAccount } from '@liferaft/api/types';
import {
  MoovBankAccountVerificationStatus,
  MoovPaymentMethodType,
} from '@liferaft/api/types';
import type { DjangoListResponse } from '@liferaft/api/utils/django-utils';
import type { NoBody } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '.';
import { useUserContext } from '../contexts';
import { useDebounce, useMoov } from '../hooks';
import * as PaymentMethodUtils from '../utils/payment-method';
import { CurrencyInput } from './forms';
import { MoovTermsForm } from './forms';
import './moov-payments.scss';

type Extends<T, U extends T> = U;
export type MoovPaymentsPaymentMethodType = Extends<
  ResourceType,
  'bankAccount' | 'card'
>;

export type Props = {
  groupId?: string;
  initiallySelectedPaymentMethodId?: string;
  onCancel?: () => void;
  onError?: () => void;
  onSuccess?: () => void;
  onPaymentMethodSelect?: (
    paymentMethod: MoovPaymentMethod | undefined
  ) => void | Promise<void>;
  onPaymentMethodVerify?: (...args: any[]) => void | Promise<void>;
  onResourceCreated?: (paymentMethod: MoovPaymentMethod) => void;
  onTermsAcceptance?: () => void | Promise<void>;
  paymentAccount?: PaymentAccount;
  paymentMethodFilters?: Record<string, string>;
  paymentMethodTypes?: MoovPaymentsPaymentMethodType[];
  selectablePredicate?: (method: MoovPaymentMethod) => boolean;
};

export function MoovPayments({
  groupId,
  initiallySelectedPaymentMethodId,
  onCancel,
  onError,
  onPaymentMethodSelect,
  onPaymentMethodVerify,
  onResourceCreated,
  onSuccess,
  onTermsAcceptance,
  paymentAccount,
  paymentMethodFilters,
  paymentMethodTypes = ['bankAccount', 'card'],
  selectablePredicate,
}: Props) {
  const { user, refresh: refreshUser } = useUserContext();
  const [moovDrops, setMoovDrops] = React.useState<Drops>();
  const { accessToken, moov, refreshAccessToken } = useMoov(
    paymentAccount?.moov_account_id
  );
  const [paymentMethods, setPaymentMethods] = React.useState<
    MoovPaymentMethod[]
  >([]);
  const [showPaymentMethodFlow, setShowPaymentMethodFlow] =
    React.useState<boolean>();
  const [selectedMethod, setSelectedMethod] =
    React.useState<MoovPaymentMethod>();

  React.useEffect(() => {
    if (accessToken && moov) {
      (async () => {
        setMoovDrops(await moov.drops());
      })();
    }
  }, [accessToken, moov]);

  const fetchPaymentMethods = (
    paymentAccountId: string,
    groupId?: string,
    userId?: string,
    network?: NetworkController
  ) => {
    network = network || new NetworkController();

    if (!groupId && userId) {
      network.request<NoBody, DjangoListResponse<MoovPaymentMethod>>(
        getPaymentMethods(paymentAccountId, userId, paymentMethodFilters),
        (result) => {
          if (result.error) return;
          if (result.data?.results?.length)
            setPaymentMethods(result.data.results);
        }
      );
    }

    if (groupId) {
      network.request<NoBody, DjangoListResponse<MoovPaymentMethod>>(
        getGroupPaymentAccountPaymentMethods(groupId, paymentAccountId),
        (result) => {
          if (result.error) return;
          if (result.data?.results?.length)
            setPaymentMethods(result.data.results);
        }
      );
    }
  };

  React.useEffect(() => {
    const network = new NetworkController();

    if (paymentAccount) {
      fetchPaymentMethods(paymentAccount.id, groupId, user.id, network);
    }
  }, [paymentAccount]);

  React.useEffect(() => {
    if (!selectedMethod && paymentMethods.length > 0) {
      const initialPaymentMethod = paymentMethods.find(
        (m) => m.id === initiallySelectedPaymentMethodId
      );

      if (initialPaymentMethod) {
        setSelectedMethod(initialPaymentMethod);
      }
    }
  }, [paymentMethods]);

  const handleCancel = () => {
    setShowPaymentMethodFlow(false);

    if (paymentAccount) {
      fetchPaymentMethods(paymentAccount.id, groupId, user.id);
    }

    refreshAccessToken();
    onCancel?.();
  };

  const handleSuccess = () => {
    setShowPaymentMethodFlow(false);

    if (paymentAccount) {
      fetchPaymentMethods(paymentAccount.id, groupId, user.id);
    }

    refreshAccessToken();
    onSuccess?.();
  };

  const handleMoovResourceCreated: OnResourceCreated = async ({
    resource,
    resourceType,
  }) => {
    if (!paymentAccount) {
      throw new Error(
        'Payment account for newly created moov payment method is unknown.'
      );
    }

    let params: Partial<CreatePaymentMethodParams> = {};

    switch (resourceType) {
      case 'bankAccount': {
        resource = resource as BankAccount;

        params = {
          ...params,
          payment_method_type: 'bank_account',
          moov_funding_source_id: resource.bankAccountID,
          bank_account_type: resource.bankAccountType,
          bank_name: resource.bankName,
          bank_last_four_account_number: resource.lastFourAccountNumber,
          routing_number: resource.routingNumber,
        };

        break;
      }
      case 'card': {
        resource = resource as MoovCard;

        params = {
          ...params,
          payment_method_type: resourceType,
          card_billing_address: resource.billingAddress,
          card_expiration: `${resource.expiration.month}/${resource.expiration.year}`,
          card_holder_name: resource.holderName,
        };
        break;
      }
      default: {
        throw new Error(
          `Uknown resource type for newly created moov resource: ${resourceType}.`
        );
      }
    }

    const network = new NetworkController();
    let result;
    if (groupId) {
      result = await network.request<
        CreatePaymentMethodParams,
        MoovPaymentMethod
      >(
        createGroupPaymentAccountPaymentMethod(
          groupId,
          paymentAccount.id,
          params as CreatePaymentMethodParams
        )
      );
    } else {
      result = await network.request<
        CreatePaymentMethodParams,
        MoovPaymentMethod
      >(
        createPaymentMethod(
          paymentAccount.id,
          user.id,
          params as CreatePaymentMethodParams
        )
      );
    }

    if (result.error) return;
    if (result.data) onResourceCreated?.(result.data);
  };

  React.useEffect(() => {
    if (accessToken && moovDrops && paymentAccount) {
      moovDrops.paymentMethods(
        {
          accountID: paymentAccount.moov_account_id,
          onCancel: handleCancel,
          onError,
          onSuccess: handleSuccess,
          onResourceCreated: handleMoovResourceCreated,
          // @ts-expect-error: type definitions for the moov package have not been updated to include this property
          paymentMethodTypes,
          open: showPaymentMethodFlow,
          token: accessToken,
        },
        'moov-payments'
      );
    }
  }, [accessToken, moovDrops, paymentAccount, showPaymentMethodFlow]);

  const handleSelectMethod = (method: MoovPaymentMethod) => {
    if (selectedMethod && selectedMethod.id == method.id) {
      if (!initiallySelectedPaymentMethodId) {
        setSelectedMethod(undefined);
        onPaymentMethodSelect?.(undefined);
      }
    } else {
      setSelectedMethod(method);
      onPaymentMethodSelect?.(method);
    }
  };

  if (!paymentAccount) {
    const handleAccept = async (token: string, onError: () => void) => {
      const network = new NetworkController();
      if (!groupId) {
        const result = await network.request<
          CreatePaymentAccountParams,
          PaymentAccount
        >(
          createPaymentAccount(user.id, {
            terms_of_service_token: token,
          })
        );

        if (result.error) {
          onError();
        } else {
          await onTermsAcceptance?.();

          refreshUser();
          refreshAccessToken();
        }
      } else {
        const result = await network.request<
          CreatePaymentAccountParams,
          PaymentAccount
        >(createGroupPaymentAccount(groupId, token));

        if (result.error) {
          onError();
        } else {
          await onTermsAcceptance?.();

          refreshAccessToken();
        }
      }
    };

    return (
      <MoovTermsForm
        autoConfirm={true}
        content={
          <p>
            Once you have read over the terms of service, please click the check
            to move on to the next step.
          </p>
        }
        onAccept={handleAccept}
      />
    );
  }

  const handleVerificationAttempt = () => {
    if (paymentAccount) {
      fetchPaymentMethods(paymentAccount.id, groupId, user.id);
    }

    onPaymentMethodVerify?.();
  };

  return (
    <>
      <div id="moov-payments" />
      <Row>
        <Col>
          <p className="d-inline-block font-weight-bold mr-2 mb-1">
            Payment Methods
          </p>
          <Button
            className="btn-link m-0 p-0 d-inline-block"
            onClick={() => setShowPaymentMethodFlow(true)}
            variant="empty">
            <small>Add new account</small>
          </Button>
          <div className="accent-underline mb-6" />
        </Col>
      </Row>
      {paymentMethods.length > 0 &&
        paymentMethods.map((method: MoovPaymentMethod, i) => {
          let selected = false;
          if (selectedMethod && method.id == selectedMethod.id) {
            selected = true;
          }

          const props = {
            groupId: groupId,
            method,
            onSelect: handleSelectMethod,
            onVerificationAttempt: handleVerificationAttempt,
            selectable: selectablePredicate?.(method),
            selected,
          };

          let rowContent;
          switch (method.payment_method_type) {
            case MoovPaymentMethodType.BANK_ACCOUNT: {
              rowContent = (
                <MethodRow
                  heading={method.bank_name}
                  subHeading={`${method.bank_account_type}  - x${method.bank_last_four_account_number}`}
                  {...props}
                />
              );
              break;
            }
            case MoovPaymentMethodType.CARD: {
              rowContent = (
                <MethodRow
                  heading={method.card_holder_name}
                  subHeading={method.card_expiration}
                  {...props}
                />
              );
              break;
            }
          }

          return (
            <Row className="mb-3" key={i}>
              <Col>{rowContent}</Col>
            </Row>
          );
        })}
      {paymentMethods.length === 0 && (
        <p className="text-center">No payment methods avilable</p>
      )}
    </>
  );
}

type MethodRowProps = {
  groupId?: string;
  method: MoovPaymentMethod;
  onSelect: (method: MoovPaymentMethod) => void;
  onVerificationAttempt: () => void;
  heading: string;
  selectable?: boolean;
  selected?: boolean;
  subHeading: string;
};

function MethodRow({
  groupId,
  heading,
  method,
  onSelect,
  onVerificationAttempt,
  selectable = true,
  selected = false,
  subHeading,
}: MethodRowProps) {
  const [isVerifying, setIsVerifying] = React.useState<boolean>(false);

  const cardClasses = ['cursor-pointer'];
  if (selected) {
    cardClasses.push('border-2', 'border-primary');
  }

  if (!selectable) {
    cardClasses.push('non-selectable');
  }

  let verificationStatus: React.ReactNode = (
    <p className="text-success">Verified</p>
  );

  if (PaymentMethodUtils.bankAccountRequiresVerification(method)) {
    verificationStatus = (
      <Button
        className="btn-link m-0 p-0 d-inline-block text-underline"
        onClick={() => setIsVerifying(true)}
        variant="empty">
        <small>
          <u>Click to finish account verification</u>
        </small>
      </Button>
    );
  }

  if (
    method.payment_method_type == MoovPaymentMethodType.BANK_ACCOUNT &&
    (method.verification_locked ||
      [
        MoovBankAccountVerificationStatus.ERRORED,
        MoovBankAccountVerificationStatus.VERIFICATION_FAILED,
      ].includes(method.bank_account_verification_status))
  ) {
    verificationStatus = (
      <p className="text-error">Verification Failed - Contact Support</p>
    );
  }

  const handleVerificationAttempt = () => {
    setIsVerifying(false);
    onVerificationAttempt();
  };

  return (
    <Card
      bodyClasses="p-4"
      cardClasses={cardClasses.join(' ')}
      onClick={() => (selectable ? onSelect(method) : undefined)}>
      <Row>
        <Col>
          <h3 className="mb-1">{heading}</h3>
          <small className="text-gray-600">{subHeading}</small>
        </Col>
        <Col className="d-flex flex-column align-items-end">
          {!isVerifying ? (
            verificationStatus
          ) : (
            <Button
              className="btn-link m-0 p-0 d-inline-block"
              onClick={() => setIsVerifying(false)}
              variant="empty">
              <small>Cancel verification</small>
            </Button>
          )}
        </Col>
      </Row>
      {isVerifying && (
        <Row className="mt-4">
          <Col>
            <MicroDepositVerificationForm
              groupId={groupId}
              onVerificationAttempt={handleVerificationAttempt}
              paymentAccountId={method.payment_account_id}
              paymentMethodId={method.id}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
}

type MicroDepositVerificationFormProps = {
  groupId?: string;
  onVerificationAttempt: () => void;
  paymentAccountId: string;
  paymentMethodId: string;
};

function MicroDepositVerificationForm({
  groupId,
  onVerificationAttempt,
  paymentAccountId,
  paymentMethodId,
}: MicroDepositVerificationFormProps) {
  const [amount1, setAmount1] = React.useState<number>();
  const [amount2, setAmount2] = React.useState<number>();
  const {
    user: { id: userId },
  } = useUserContext();

  const missingOrInvalidAmounts =
    amount1 === undefined ||
    amount2 === undefined ||
    Number.isNaN(amount1) ||
    Number.isNaN(amount2) ||
    amount1 > 0.49 ||
    amount2 > 0.49 ||
    amount1 === 0 ||
    amount2 === 0;

  const [handleSubmit, isSubmitting] = useDebounce(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (missingOrInvalidAmounts) return;

      const network = new NetworkController();

      let requestConfiguration;
      if (groupId) {
        requestConfiguration = verifyGroupPaymentMethod(
          paymentAccountId,
          paymentMethodId,
          groupId,
          { amount_1: amount1, amount_2: amount2 }
        );
      } else {
        requestConfiguration = verifyPaymentMethod(
          paymentAccountId,
          paymentMethodId,
          userId,
          {
            amount_1: amount1,
            amount_2: amount2,
          }
        );
      }

      const result = await network.request(requestConfiguration);

      if (!result.error) onVerificationAttempt();
    }
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <CurrencyInput
            label="Micro deposit amount #1"
            onChange={setAmount1}
          />
          <span>Cannot exceed $0.49</span>
        </Col>
        <Col>
          <CurrencyInput
            label="Micro deposit amount #2"
            onChange={setAmount2}
          />
          <span>Cannot exceed $0.49</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="float-right py-1 px-2"
            disabled={isSubmitting || missingOrInvalidAmounts}
            type="submit"
            variant="primary">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
