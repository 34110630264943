import * as Sentry from '@sentry/react';
import React from 'react';

import { Loader, TokenAuthenticationBoundary } from '@liferaft/core/components';
import {
  AuthenticationProvider,
  BannerContextProvider,
} from '@liferaft/core/contexts';

import { ProtectedEntry, PublicEntry } from '@/components';

export function App() {
  return (
    <React.StrictMode>
      <Sentry.ErrorBoundary>
        <AuthenticationProvider>
          <BannerContextProvider>
            <TokenAuthenticationBoundary
              loadingApp={<Loader />}
              pollingIntervalMs={300_000}
              protectedApp={<ProtectedEntry />}
              publicApp={<PublicEntry />}
            />
          </BannerContextProvider>
        </AuthenticationProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
}
