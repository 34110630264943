import { DjangoRequestConfiguration } from '../utils/django-utils';

type SendVerificationCodeParams = {
  phone_number: string;
};

export function sendVerificationCode(
  phoneNumber: string
): DjangoRequestConfiguration<SendVerificationCodeParams> {
  return new DjangoRequestConfiguration<SendVerificationCodeParams>()
    .post()
    .body({ phone_number: phoneNumber })
    .url('/api/groups/level-funded-mec/send-verification-code/');
}

type CheckVerificationCodeParams = {
  phone_number: string;
  verification_code: string;
};

export function checkVerificationCode(
  phoneNumber: string,
  verificationCode: string
): DjangoRequestConfiguration<CheckVerificationCodeParams> {
  return new DjangoRequestConfiguration<CheckVerificationCodeParams>()
    .post()
    .body({ phone_number: phoneNumber, verification_code: verificationCode })
    .url('/api/groups/level-funded-mec/check-verification-code/');
}
