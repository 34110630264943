import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { AnalyticsContextProvider } from '@liferaft/core/contexts';
import '@liferaft/core/theme/main.scss';

import { App } from '@/components';
import {
  NODE_ENV,
  RELEASE_SHA,
  SEGMENT_KEY,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
} from '@/environment';

if (NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: 'claims-web@' + RELEASE_SHA,
  });
}

ReactDom.render(
  <BrowserRouter>
    <AnalyticsContextProvider writeKey={SEGMENT_KEY}>
      <App />
    </AnalyticsContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
