import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { NoBody } from '../utils/network';

export type CreatePaymentAccountParams = {
  terms_of_service_token: string;
};

export function createPaymentAccount(
  userId: string,
  body: CreatePaymentAccountParams
): DjangoRequestConfiguration<CreatePaymentAccountParams> {
  return new DjangoRequestConfiguration<CreatePaymentAccountParams>()
    .post()
    .body(body)
    .url(`/api/users/${userId}/payment-account/`);
}

export function getPaymentAccount(
  paymentAccountId: string,
  userId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/users/${userId}/payment-accounts/${paymentAccountId}/`);
}
