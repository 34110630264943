import type { YearlyGroupEnrollment } from '@liferaft/api/types';
import { GroupEnrollmentType } from '@liferaft/api/types';

import * as dates from './dates';

export function getEnrollmentDateDisplay(
  enrollment: YearlyGroupEnrollment
): string {
  let benefitDates = '';

  if (enrollment.start_date && enrollment.end_date) {
    benefitDates = `${dates.getDisplayDate(
      enrollment.start_date
    )} - ${dates.getDisplayDate(enrollment.end_date)}`;
  } else if (enrollment.start_date) {
    benefitDates = `${dates.getDisplayDate(enrollment.start_date)}`;
  }

  return benefitDates;
}

export function getEnrollmentMonthlyCost(
  enrollment: YearlyGroupEnrollment
): number | undefined {
  switch (enrollment.enrollment_type) {
    case GroupEnrollmentType.LEVEL_FUNDED_MEC: {
      return (
        Number(enrollment.per_member_admin_fee) +
        Number(enrollment.per_member_reserve_amount)
      );
      break;
    }
  }
}
