import React from 'react';
import { Navigate } from 'react-router-dom';

import { Plaid, useLocalStorage } from '../hooks';

export function PlaidOAuthRedirect() {
  const store = useLocalStorage();
  const [uriCaptured, setURICaptured] = React.useState<boolean>(false);

  React.useEffect(() => {
    Plaid.setReceivedRedirectUri(store, window.location.href);
    setURICaptured(true);
  }, []);

  return uriCaptured ? <Navigate to="/" /> : null;
}
