import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PhoneNumberLogin } from '@/pages';

export function PublicEntry() {
  return (
    <Routes>
      <Route element={<PhoneNumberLogin />} path="/" />
    </Routes>
  );
}
